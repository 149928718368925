import React, { useState, useEffect } from 'react';
import WindChart from './WindChart';


function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return Date.parse(result);
}

function Weather(props) {
    const [data, setData ] = useState('');  
    // Check what date it is. 
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    //console.log(`${day} ${month} ${year}`);
    //console.log(addDays(`${year}/${month}/${day}`, 6))
    //console.log(result.setDate(result.getDate() + days);)
    const endepoch = addDays(`${year}/${month}/${day}`, 6)
    //console.log()
    const fetchData = async () => {
      //fetch(`http://localhost:8080/api/wind?lat=${props.lat}&lng=${props.lng}&params=airTemperature,windDirection,windSpeed,gust&end=${endepoch}`)      
      fetch(`https://foiling-api.kite4fun.nl/api/wind?lat=${props.lat}&lng=${props.lng}&params=airTemperature,windDirection,windSpeed,gust&end=${endepoch}`)
      .then(response => response.json())
      .then(resultData => {
        //console.log(resultData)
        setData(resultData)
      })
      .catch(error => ({ "data": "error", "msg": "Server info could not be retrieved." }))
    }

    useEffect(() => {
    fetchData();
    }, [])


    return(
        // console.log(data),
        <>
        { data && data.data !== 'error' && data.data.hours ? (
          // <>{data.data.hours.map(({ time }) => time.split('+')[0])}</>
          <WindChart data={data.data.hours} location={props.location}/>
        ) : "No data fetched."}  
        </>
    )

};

export default Weather;