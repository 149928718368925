import React, { useState } from "react";
import { Container, Table, Collapse, Button, CardBody, Card } from "reactstrap";

const WindTable = (props) => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    return(       
        <div style={{height: '50vh'}}>
        <Container>
        {/* <Button color="secondary" onClick={toggle} style={{ marginBottom: '1rem' }}>
          WindTable
        </Button> */}
        </Container>
        <Collapse isOpen={isOpen} {...props}>
        <Container style={{backgroundColor: 'lightsteelblue'}}>
             <Table striped bordered hover>
                <thead> 
                <tr className="table-primary">
                    <th>Beaufort</th>
                    <th>Knots</th>
                    <th>m/s</th>
                    <th>km/h</th>
                    <th>mph</th>
                    <th>scene</th>
                </tr>
                </thead>
                <tbody>
                <tr className="table-secondary">
                    <th scope="row">0</th>
                    <td>1</td>
                    <td>0 - 0.2</td>
                    <td>1</td>
                    <td>1</td>
                    <td>Calm</td>
                </tr>
                <tr>
                    <th scope="row">1</th>
                    <td>1-3</td>
                    <td>0.3 - 1.5</td>
                    <td>1-5</td>
                    <td>1-3</td>
                    <td>Light Air</td>
                </tr>
                <tr className="table-secondary">
                    <th scope="row">2</th>
                    <td>4-6</td>
                    <td>1.6 - 3.3</td>
                    <td>6-11</td>
                    <td>4-7</td>
                    <td>Light Breeze</td>
                </tr>               <tr>
                    <th scope="row">3</th>
                    <td>7-10</td>
                    <td>3.4 - 5.4</td>
                    <td>12-19</td>
                    <td>8-12</td>
                    <td>Gentle Breeze</td>
                </tr>
                <tr className="table-secondary">
                    <th scope="row">4</th>
                    <td>11-15</td>
                    <td>5.5 - 7.9</td>
                    <td>20-28</td>
                    <td>13-17</td>
                    <td>Moderate Breeze</td>
                </tr>
                <tr>
                    <th scope="row">5</th>
                    <td>16-21</td>
                    <td>8.0 - 10.7</td>
                    <td>29-38</td>
                    <td>18-24</td>
                    <td>Fresh Breeze</td>
                </tr>
                <tr className="table-secondary">
                    <th scope="row">6</th>
                    <td>22-27</td>
                    <td>10.8 - 13.8</td>
                    <td>39-49</td>
                    <td>25-30</td>
                    <td>Strong Breeze</td>
                </tr>
                <tr>
                    <th scope="row">7</th>
                    <td>28-33</td>
                    <td>13.9 - 17.1</td>
                    <td>50-61</td>
                    <td>31-38</td>
                    <td>Near Gale</td>
                </tr>
                <tr className="table-secondary">
                    <th scope="row">8</th>
                    <td>34-40</td>
                    <td>17.2 - 20.7</td>
                    <td>62-74</td>
                    <td>39-46</td>
                    <td>Gale</td>
                </tr>
                <tr>
                    <th scope="row">9</th>
                    <td>41-47</td>
                    <td>20.8 - 24.4</td>
                    <td>75-88</td>
                    <td>47-54</td>
                    <td>Severe Gale</td>
                </tr>
                <tr className="table-secondary">
                    <th scope="row">10</th>
                    <td>48-55</td>
                    <td>24.5-28.4</td>
                    <td>89-102</td>
                    <td>55-63</td>
                    <td>Strong Storm</td>
                </tr>
                <tr>
                    <th scope="row">11</th>
                    <td>56-63</td>
                    <td>28.5-32.6</td>
                    <td>103-117</td>
                    <td>64-73</td>
                    <td>Violent Storm</td>
                </tr>
                <tr className="table-secondary">
                    <th scope="row">12</th>
                    <td>64-71</td>
                    <td>32.7</td>
                    <td>118</td>
                    <td>74</td>
                    <td>Hurricane</td>
                </tr>
                </tbody>
            </Table>
        </Container>
        </Collapse>
        </div>
        );
    };

    export default WindTable;